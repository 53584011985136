<template>
  <div v-if="showToggle">
    <div class="alert-content-title background__blue_title">
      <p class="title-secondary color__blue_main fs-20">{{$t('page-setting.title') }}</p>
    </div>
    <div class="page-main-content " @keyup.enter="submit()">
      <div class="columns switch-box mt-0">
        <div class="column">
          <span class="input-title color__blue_main fs-16">{{$t('page-setting.text-switch') }}</span>
        </div>
      </div>
      <div class="columns mt-0 m__bottom--6">
        <div class="column align-self-start p__top--10">
          <div class="field">
            <input id="settingInterval" type="checkbox" name="setting"
                   class="switch is-rounded font-14 is-pink" v-model="send_alert">
            <label for="settingInterval"></label>
          </div>
        </div>
      </div>
      <div class="columns mt-0">
        <div class="column is-full-width p__top--0 page-setting-box">
          <p class="color__gray_text fs-12">
            <span class="color__blue_main">※</span>アラート送信をOFFにすると、すべてのアラートが送信停止になります。
          </p>
          <p class="color__gray_text fs-12">新しくアラート設定を行っても送信されません。</p>
        </div>
      </div>
      <hr/>
      <div class="field is-grouped is-grouped-centered m__top--40 m__bottom--60">
        <div class="control">
          <button class="button btn-save background__blue_main color__white fs-16" @click="submit()">{{$t('buttons.submit')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {ACTION_SET_ACTIVE_SIDEBAR} from "@/stores/common/actions";
  import {PageSettingService} from "@/services";
  import {momentFormat} from "../../filters";
  import {mapGetters} from "vuex";


  export default {
    name: "PageSetting",

    props: {
      title: {
        type: String,
        default: '',
      }
    },
    data() {
      return {
        send_alert: false,
        pageSetting: {
          send_alert: 0,
          exchange_deadline: '',
        },
        showToggle: false,
      }
    },
    methods: {
      getSetting() {
        PageSettingService.getSettingAlert()
          .then((res) => {
            if (res && res.data) {
              this.pageSetting = res.data
              this.pageSetting = {
                exchange_deadline: res.data.exchange_deadline ? new Date(res.data.exchange_deadline) : '',
                send_alert: res.data.send_alert
              }
              this.send_alert = this.pageSetting.send_alert
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      submit() {
        let data = {
          exchange_deadline: this.pageSetting.exchange_deadline ? momentFormat(new Date(this.pageSetting.exchange_deadline)) : '',
          send_alert: this.pageSetting.send_alert
        }
        // if (this.pageSetting.send_alert * 1 === 0) {
        //   delete data.exchange_deadline
        // }
        PageSettingService.settingAlert(data)
          .then(() => {
            this.$toast.success(this.$t('page-setting.message.success'))
            this.getSetting()
          })
          .catch((err) => {
            this.error_server = err.data.errors
          })
      }
    },
    created() {
      this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, "page-setting")
      this.getSetting();
    },
    watch: {
      send_alert: function (val) {
        this.pageSetting.send_alert = val ? 1 : 0
      },
      isLoading(val) {
        if (!val) {
          setTimeout(() => {
            this.showToggle = true
          }, 100)
        }
      }
    },
    computed: {
      ...mapGetters(['isLoading']),
    },
  }
</script>

<style lang="scss" scoped>
  .alert-content-title {
    padding: 10px 60px;
    border-radius: 10px 10px 0 0;
  }
  .title-secondary {
    font-weight: bold;
  }
  .input-title {
    font-weight: bold;
  }
  .page-setting-box {
    min-height: 40vh;
  }
  .page-main-content {
    padding: 10px 60px;
    border-radius: 0 0 10px 10px;
    .columns {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 0 !important;
      .column {
          padding-left: 0px;
      }
    }
  }
  .date-time {
    width: 160px;
  }
  .img-calendar {
    width: 20px;
    height: 20px;
  }
  .switch-box {
    margin-bottom: 0px;
    .column {
      padding-bottom: 0px;
    }
  }
  .btn-save {
    border-radius: 20px !important;
    border: none !important;
    width: 140px;
    height: 35px !important;
    font-weight: bold;
    font-size: 16px !important;
  }
  .switch[type=checkbox]+label::before {
    width: 60px;
    height: 22px;
    background: $white_pink;
  }
  .switch[type=checkbox] + label::after{
    width: 15px;
    height: 15px;
    top: 3px;
    background: $blue_main
  }
  .switch[type=checkbox]:checked+label:after {
    left: 2.625rem;
  }
  .switch[type=checkbox]+label:after {
    transition: all .25s ease-out !important;
  }
  ::v-deep {
    .mx-input {
      height: 40px;
      font-size: 12px !important;
      font-weight: bold !important;
      color: #B1B1B1 !important;
    }
    .mx-icon-calendar {
      height: 20px;
    }
  }
  .switch[type=checkbox]:focus + label::after,
  .switch[type=checkbox]:focus + label::before,
  .switch[type=checkbox]:focus + label:after,
  .switch[type=checkbox]:focus + label:before {
    outline: none !important;
  }
  @media screen and (max-width: 1024px) {
    .line-tablet {
      margin-top: 150px !important;
    }
    .alert-content-title {
      padding: 10px 20px !important;
      border-radius: 10px 10px 0 0;
    }
    .page-main-content {
      padding: 10px 20px !important;
      .columns {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 0 !important;
        .column {
          padding-left: 0px !important;
        }
      }
    }
  }

</style>